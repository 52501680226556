.d-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 100px;
    grid-gap: 7px;
}

.item {
    position: relative;
}

.item:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
}

.item:nth-child(2) {
    grid-column: 2;
    grid-row: 0 / 3;
}

.item:nth-child(3) {
    grid-column: 2;
    grid-row: 2 / 3;
}

.item:nth-child(4) {
    grid-column: 2;
    grid-row: 3 / 3;
}

.item a {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
}

.item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#services .gradient {
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(72, 15, 144, 0.4) 100%);
}

#services .view {
    background-image: url('../../assets/landingservices3.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center bottom;
    min-height: 30vh;
}
